<template>
  <b-container class="d-flex align-items-center min-vh-100">
    <CRow class="w-100 justify-content-center">
      <CCol md="6">
        <div class="w-100">
          <div class="clearfix">
            <h1 class="float-left display-3 mr-4">404</h1>
            <h4 class="pt-3">Oops! You're lost.</h4>
            <p class="text-muted">
              The page you are looking for was not found.
            </p>
            <div class="text-center">
              <router-link to="/welcome">Back to Home</router-link>
            </div>
          </div>
        </div>
      </CCol>
    </CRow>
  </b-container>
</template>

<script>
export default {
  name: "Page404",
};
</script>
